import React from 'react';
import {Helmet} from "react-helmet";
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Footer from "../components/Footer"
import p04flautaAudio from "../audio/p04flautaAudio.m4a"
import p04burroAudio from "../audio/p04burroAudio.m4a"
import p04VO from "../audio/p04VO.m4a"

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
const images = importAll(require.context('../images/', false, /.jpg$|.png/));

class Page04 extends React.Component {
  constructor(props){
    super(props)
    this.state = { active: false,
                   cencerro: images["p04_cencerroBoton.png"],
                   burroanim: false,
                 }
  }
  componentDidMount() {
    this.burroTime();
  }
  componentWillUnmount() {
    this.burroTime(5)
  }
  playAudio = (x) => {
    const audioEl = document.getElementsByClassName("audio-element")[x]
    if(x===0){
      if(!this.state.active){
        audioEl.play()
      }
      else{ audioEl.pause();audioEl.currentTime = 0; }
  }
  else {
    audioEl.play()
  }


  }
getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
	}
burroTime = (x) => {
  this.setState({burroanim: false})
	let randomNumberResult = this.getRandomInt(1000,7000)
  console.log("burroTime", randomNumberResult)
  let t = setTimeout(this.burroPataAnim, 5000);
  if(x===5){
    console.log("cleared!")
    clearTimeout(t)
  }
}
burroPataAnim = () => {
  console.log("fire")
	this.setState({burroanim: true})

  /*burroPata1[0].addEventListener("animationend", () => {burroPata1[0].classList.remove("active"); burroPata2[0].classList.remove("active"); this.burroTime();});*/
}
handleCencerroClick = () => {
  this.setState({ active: !this.state.active }, () => {
      if(this.state.active){
        setTimeout( () => {this.setState({cencerro: images["resetBoton.png"]})}, 1000 )
      }
      else { this.setState({cencerro: images["p04_cencerroBoton.png"]}) }
    })
}
checkCencerro = () => {
  if(this.state.active) {
    console.log("active")
  }
}
  render() {

    return (
    <div className="main-container">
    <Link to="/Page03">
      <div className="page-turner">
        <img style={{transform: "scaleX(-1)", opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
      </div>
    </Link>
    <Link to="/Page05">
      <div className="page-turner right-page-pos">
      <img style={{opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
      </div>
    </Link>
      <div className="centered-container">
        <div className="backContainer004">
        <StaticQuery
          query={graphql`
            query {
              imageOne: file(relativePath: { eq: "p04_back.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          `}
    render={data => (
      <Img fluid={data.imageOne.childImageSharp.fluid} />
    )}
  />
        </div>
        <div className="p04Cuentamelo" onClick={() => this.playAudio(2)}>
          <img className="hw" src={images["botonCuentamelo.png"]} alt="" />
        </div>
        <div className="_idContainer028">
          <div className="_idContainer026">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p04_burroColaTip.png"]} alt="" />
          </div>
          <div className="_idContainer027">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p04_burroCola.png"]} alt="" />
          </div>
        </div>
        <div className="_idContainer029">
          <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p04_burro.png"]} alt="" />
        </div>
        <div className="_idContainer030">
          <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p04_comida.png"]} alt="" />
        </div>

        <div className="_idContainer031">
          <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p04_burroTop.png"]} alt="" />
        </div>
        <div className={"_idContainer032" + (this.state.active ? " rotAnim" : "") }>
          <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p04_flauta.png"]} alt="" />
        </div>
        <div className={"_idContainer036" + (this.state.active ? " active" : "") }>
          <div className="_idContainer033">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p04_burroOrejaIzq.png"]} alt="" />
          </div>
          <div className="_idContainer034">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p04_burroCabeza.png"]} alt="" />
          </div>
          <div className="_idContainer035">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p04_burroOrejaDer.png"]} alt="" />
          </div>
        </div>
        <div className={"_idContainer039" + (this.state.burroanim ? " active" : "") }>
          <div className="_idContainer037">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p04_burroPataTop.png"]} alt="" />
          </div>
          <div className={"_idContainer038" + (this.state.burroanim ? " active" : "")} onAnimationEnd={() => this.burroTime()}>
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p04_burroPataBott.png"]} alt="" />
          </div>
        </div>
        <div className={"_idContainer040" + (this.state.active ? " active" : "") }>
          <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p04_nota06.png"]} alt="" />
        </div>
        <div className={"_idContainer041" + (this.state.active ? " active" : "") }>
          <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p04_nota05.png"]} alt="" />
        </div>
        <div className={"_idContainer042" + (this.state.active ? " active" : "") }>
          <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p04_nota04.png"]} alt="" />
        </div>
        <div className={"_idContainer043" + (this.state.active ? " active" : "") }>
          <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p04_nota03.png"]} alt="" />
        </div>
        <div className={"_idContainer044" + (this.state.active ? " active" : "") }>
          <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p04_nota02.png"]} alt="" />
        </div>
        <div className={"_idContainer045" + (this.state.active ? " active" : "") }>
          <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p04_nota01.png"]} alt="" />
        </div>
        <div className="_idContainer046 Basic-Text-Frame">
          <span>El burro tocaba la flauta.</span>
        </div>
        <div className="_idContainer047" onClick={() => this.playAudio(1)}>
          <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["4251.png"]} alt="" />
        </div>
        <div className={"_idContainer048" + (this.state.active ? " active" : "") }
            onClick={() => {this.playAudio(0); this.handleCencerroClick()}}
            >
          <img className="cencerroReset _idGenObjectAttribute-1 _idGenObjectAttribute-2" src={this.state.cencerro} alt="" />
        </div>
        <audio className="audio-element">
          <source src={p04flautaAudio}></source>
        </audio>
        <audio className="audio-element">
          <source src={p04burroAudio}></source>
        </audio>
        <audio className="audio-element">
          <source src={p04VO}></source>
        </audio>

      </div>
    <Footer />
    </div>
    )
  }
}
export default Page04;
